<script setup lang="ts">
import type { IShopSelectShop } from "@magnit/unit-shop-select/src/types";
import ShopSelectListLoading from "@magnit/unit-shop-select/src/components/ShopSelectListLoading.vue";
import ShopSelectListEmpty from "@magnit/unit-shop-select/src/components/ShopSelectListEmpty.vue";

defineProps<{
  loading: boolean;
  shops: IShopSelectShop[];
}>();
const emit = defineEmits<{
  "submit:store": [code: string];
}>();

const handleClickStore = (storeCode: string) => emit("submit:store", storeCode);
</script>

<template>
  <div class="shops-list">
    <ShopSelectListLoading v-if="loading" />
    <template v-else>
      <ShopSelectListEmpty v-if="!shops.length" desc="" />
      <template v-else>
        <VShopsListItem
          v-for="single in shops"
          :key="single.code"
          :shop="single"
          @click:store="() => handleClickStore(single.code)"
        />
      </template>
    </template>
  </div>
</template>

<style scoped lang="postcss">
.shops-list {
  display: flex;
  flex-direction: column;
  min-height: 578px;
}
</style>
