<script setup lang="ts">
import type { IShopSelectShop } from "@magnit/unit-shop-select/src/types";
import VText from "@magnit/core/src/components/VText/VText.vue";
import WatchesIcon from "@magnit/icons/src/assets/icons/watches-16.svg";
import ArrowIcon from "@magnit/icons/src/assets/icons/arrow-right-corner-24.svg";
import LogoV1Icon from "@magnit/icons/src/assets/icons/logo-v1-36-color.svg";

const props = defineProps<{
  shop: IShopSelectShop;
}>();
const emit = defineEmits<{
  "click:store": [];
}>();

const label = computed(() => props.shop.address);
const schedule = computed(() => {
  const { openingHours, closingHours } = props.shop;
  if (!openingHours || !closingHours) return "";
  return `${openingHours} - ${closingHours}`;
});
const handleItemClick = () => {
  emit("click:store");
};
</script>

<template>
  <div class="shops-list__item" @click="handleItemClick">
    <div class="shops-list__item--icon">
      <LogoV1Icon />
    </div>
    <div class="shops-list__item--info">
      <VText
        tag="p"
        font="body-large-accent"
        class="shops-list__item--label"
        :title="label"
      >
        {{ label }}
      </VText>
      <VText
        v-if="schedule"
        tag="p"
        font="body-small-regular"
        color="secondary"
        class="shops-list__item--schedule"
      >
        <WatchesIcon class="shops-list__item--schedule-icon" />
        {{ schedule }}
      </VText>
    </div>
    <div class="shops-list__item--arrow">
      <ArrowIcon />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.shops-list__item {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid var(--pl-divider-primary);
  padding: var(--pl-unit-x4) 0;

  &--icon {
    align-self: baseline;
    margin-top: 4px;
    margin-right: 10px;
  }

  &--info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &--label,
  &--schedule {
    margin: 0;
  }

  &--schedule {
    display: flex;
    gap: var(--pl-unit-x1);
    color: var(--pl-project-v1-green-primary);

    &-icon {
      position: relative;
      top: 2px;
    }
  }

  &--label {
    margin-bottom: 2px;
  }

  &--arrow {
    margin-top: 6px;
    margin-right: 6px;
    transition: all 0.25s;
  }

  &:hover .shops-list__item--arrow {
    margin-right: 2px;
  }
}
</style>
